import React, {useEffect, useState} from "react";
import SideBar from "../../components/SideBar/SideBar";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import { Container, Row, Col, Alert, FormGroup, Label, Input, Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Typeahead, withAsync } from 'react-bootstrap-typeahead';

const AsyncTypeahead = withAsync(Typeahead);

function InventoryFiscalCreatePage () {

  useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  
  // usuario
  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);

  // Alertas
  const alert = useSelector(state => state.alert);
  // Mostrar alertas
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  //Form Data
  const { handleSubmit, register, errors, reset, control } = useForm();

  return (
    <>
      <div className="d-flex" id="wrapper">
        <SideBar/>
        <div id="page-content-wrapper">
          <AdminNavbar/>
          <div className="container-fluid">
            <Container>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }}>
                  <h3 style={{ fontWeight:'bold', fontStyle: 'italic' }}>Inventario fiscal</h3>
                  {alert.message &&
                    <Alert color={alert.type} isOpen={visible} fade={true}>
                      <div className="container">
                        {alert.message}
                        <button
                          type="button"
                          className="close"
                          aria-label="Close"
                          onClick={onDismiss}
                        >
                          <span aria-hidden="true">
                          <i className="now-ui-icons ui-1_simple-remove"></i>
                          </span>
                        </button>
                      </div>
                    </Alert>
                  }

                  <Row form>
                    <Col>
                      <FormGroup className="mr-3">
                        <Label for="issueDate">Fecha de emisión</Label>
                        <Input
                          type="date"
                          name="issueDate"
                          id="issueDate"
                          required
                          style={{width:'40%'}}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                    
                  <Col>
                  </Col>
                  <Row>
                  {/* Nombre o Razón Social */}
                  <FormGroup>
                      <Label for="name">Nombre o Razón Social</Label>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Ingrese el nombre o razón social"
                        required
                      />
                    </FormGroup>
                  <Col md={1.5}>
                      <FormGroup>
                        <Label for="documentType">V, J, E...</Label>
                        <select
                          className={'form-control'}
                        >
                          <option name="" value=""></option>
                          <option name="V" value="V">V</option>
                          <option name="J" value="J">J</option>
                          <option name="E" value="E">E</option>
                          <option name="G" value="G">G</option>
                          <option name="P" value="P">P</option>
                          <option name="R" value="R">R</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                      <Label for="document">Documento</Label>
                      <Controller
                          name="document"
                          
                          control={control}
                          rules={{
                              required: "El documento es requerido",
                          }}
                          render={({}) => (
                              <AsyncTypeahead
                                  clearButton
                                  allowNew
                                  placeholder="Ingrese el numero de documento"
                                  newSelectionPrefix="Añadir:"
                                  // filterBy={filterBy}
                                  // ref={clientNamesRef}
                                  // id="async-example"
                                  // isInvalid={errorsSale.document ? true:false}
                                  // isLoading={isLoading}
                                  minLength={3}
                                  // onSearch={handleSearch}
                                  // useCache={false}
                                  // onChange={e => handleChange(e)}
                                  // options={options}
                                  emptyLabel="No hay resultados"
                                  labelKey="document"
                                  // selected={selected}
                              />
                          )}
                      />
                      {/* {errorsSale.document && <div className="invalid-feedback d-block">{errorsSale.document.message}</div>} */}
                      </FormGroup>
                      </Col>
                  </Row>
                    

                    {/* Dirección */}
                    <FormGroup>
                      <Label for="address">*Dirección</Label>
                      <Input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Ingrese la dirección"
                        required
                      />
                    </FormGroup>

                    {/* Producto */}
                    <FormGroup>
                      <Label for="product">*Producto</Label>
                      <Input
                        type="text"
                        name="product"
                        id="product"
                        placeholder="Ingrese el producto"
                        required
                      />
                    </FormGroup>

                    {/* Cantidad */}
                    <FormGroup>
                      <Label for="quantity">*Cantidad</Label>
                      <Input
                        type="number"
                        name="quantity"
                        id="quantity"
                        placeholder="Ingrese la cantidad"
                        required
                      />
                    </FormGroup>

                    {/* Precio */}
                    <FormGroup>
                      <Label for="price">Precio</Label>
                      <Input
                        type="number"
                        name="price"
                        id="price"
                        placeholder="Ingrese el precio"
                      />
                    </FormGroup>

                    {/* Base Imponible, IVA y Total */}
                    <FormGroup>
                      <Label for="baseImponible">Base Imponible</Label>
                      <Input
                        type="number"
                        name="baseImponible"
                        id="baseImponible"
                        placeholder="Ingrese la base imponible"
                      />
                    </FormGroup>
                    
                    <FormGroup>
                      <Label for="iva">IVA</Label>
                      <Input
                        type="number"
                        name="iva"
                        id="iva"
                        placeholder="Ingrese el IVA"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="total">Total</Label>
                      <Input
                        type="number"
                        name="total"
                        id="total"
                        placeholder="Ingrese el total"
                      />
                    </FormGroup>

                    {/* Botón Guardar */}
                    <FormGroup className="text-center">
                      <button type="submit" className="btn btn-primary">
                        Guardar
                      </button>
                    </FormGroup>
                    </Col>
                  </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default InventoryFiscalCreatePage;
