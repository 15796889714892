/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { salesActions } from '../../actions';
import { history } from '../../helpers';
// reactstrap components
import {
	Collapse,
} from "reactstrap";
import { useLocation, NavLink } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';
import fattoria from '../../assets/img/fattoria.png';

/**
 * Iconos
 */
import { Icon } from '@iconify/react';
import balanceScale from '@iconify/icons-fa-solid/balance-scale';
import storeIcon from '@iconify/icons-fa-solid/store';
import listAlt from '@iconify/icons-fa-solid/list-alt';
import creditCard from '@iconify/icons-fa-solid/credit-card';
import userFriends from '@iconify/icons-fa-solid/user-friends';
import donateIcon from '@iconify/icons-fa-solid/donate';
import listUl from '@iconify/icons-fa-solid/list-ul';
import dollyIcon from '@iconify/icons-fa-solid/dolly';
import cashRegister from '@iconify/icons-fa-solid/cash-register';
import boxesIcon from '@iconify/icons-fa-solid/boxes';
import clipboardIcon from '@iconify/icons-fa-solid/clipboard';
import clipboardList from '@iconify/icons-fa-solid/clipboard-list';
import undoAlt from '@iconify/icons-fa-solid/undo-alt';
import moneyBillWave from '@iconify/icons-fa-solid/money-bill-wave';
import clipboardCheck from '@iconify/icons-fa-solid/clipboard-check';
import bookIcon from '@iconify/icons-fa-solid/book';
import bookMedical from '@iconify/icons-fa-solid/book-medical';
import bookDead from '@iconify/icons-fa-solid/book-dead';
import cartPlus from '@iconify/icons-fa-solid/cart-plus';
import doorOpen from '@iconify/icons-fa-solid/door-open';
import chartLine from '@iconify/icons-fa-solid/chart-line';
import fileAlt from '@iconify/icons-fa-solid/file-alt';
import plusCircle from '@iconify/icons-fa-solid/plus-circle';
import editIcon from '@iconify/icons-fa-solid/edit';
import angleUp from '@iconify/icons-fa-solid/angle-up';
import angleDown from '@iconify/icons-fa-solid/angle-down';
import bullhornIcon from '@iconify/icons-fa-solid/bullhorn';
import boxIcon from '@iconify/icons-fa-solid/box';
import briefcaseIcon from '@iconify/icons-fa-solid/briefcase';
import folderPlus from '@iconify/icons-fa-solid/folder-plus';
import folderMinus from '@iconify/icons-fa-solid/folder-minus';
import tasksIcon from '@iconify/icons-fa-solid/tasks';
import tableIcon from '@iconify/icons-fa-solid/table';
import pencilAlt from '@iconify/icons-fa-solid/pencil-alt';
import maleIcon from '@iconify/icons-fa-solid/male';
import trashIcon from '@iconify/icons-fa-solid/trash';


//Menu lateral en admin
function SideBar() {

	/**
	 * Antes de cerrar la ventana o tab si hay ventas por procesar enviar un alerta
	 */
	window.addEventListener("beforeunload", (ev) => {
		let inProcess = localStorage.getItem('SALEPROCESS');
		if (inProcess) {
			ev.preventDefault();
			return ev.returnValue = '¡Tiene ventas por procesar pendientes!';
		}
	});

	const dispatch = useDispatch();
	const location = useLocation();
	const user = useSelector(state => state.authentication.user);

	// collapse states and functions
	const [collapses, setCollapses] = useState([]);

	const changeCollapse = collapse => {

		if (collapses.includes(collapse)) {
			setCollapses(collapses.filter(prop => prop !== collapse));
		} else {
			setCollapses([...collapses, collapse]);
		}
	};

	/**
	 * En momentos de inactividad realizar consulta de monedas productos y terminales
	 * - Ejecuta la funcion salesDataFormOffline cada 10 segundos
	 */
	const handleOnIdle = () => {
		//console.log('ultima actividad', getLastActiveTime());
		dispatch(salesActions.salesDataFormOffline(user.agency.id));
		reset();
	}
	const { getLastActiveTime, reset } = useIdleTimer({
		timeout: 10000,//10 segundos
		onIdle: handleOnIdle,
		debounce: 500
	})

	//End timer
	useEffect(() => {
		let page = location.pathname;

		if (page === "/users" || page === "/register-user" || page === "/sellers" || page === "/register-seller"   || page === "/update-seller") {
			changeCollapse(1);
		}

		if (page === "/agency" || page === "/register-agency" || page === "/agency-close-history") {
			changeCollapse(2);
		}

		if (page === "/product" || page === "/register-product" || page === "/product-history" || page === "/combos" || page === "/create-combo" || page === "/record-combo") {
			changeCollapse(3);
		}

		// Inventarios
		if (page === "/register-inventory" || page === "/readjustment" || page === "/inventory-entry-history" || page === "/inventory" || page === "/order-history" || page === "/order-helper") {
			changeCollapse(4);
		}

		if (page === "/register-sale" || page === "/offline-sales" || page === "/register-wholesale" || page === "/credit-payment") {
			changeCollapse(5);
		}

		// Monedas
		if (page === "/coin" || page === "/register-coin") {
			changeCollapse(6);
		}

		// Terminal
		if (page === "/terminal" || page === "/register-terminal" || page === "/update-terminal" || page === "/terminal-reports") {
			changeCollapse(7);
		}
		if (page === "/inventory-sell" || page === "/inventory-history" || page === "/inventory-report" || page === "/balance-report" || page === "/inventory-reweigh"
			|| page === "/payment-methods-report" || page === "/payment-methods-history" || page === "/payment-methods-general-report" || page === "/inventory-report-daily" || page === "/departures"
			|| page === "/inventory-report-plus" || page === "/inventory-adjustment-history" || page === "/cron-history" || page === "/offer-history" || page === "/inventory-offer"
			|| page === "/client-list" || page === "/update-client" || page === "/wholesale-client-list" || page === "/update-wholesale-client" || page === "/commissions-report"
			|| page === "/sales-user" || page === "/sales" || page === "/operators-performance") {
			changeCollapse(8);
		}
		if (page === "/departure") {
			changeCollapse(9);
		}

		if (page === "/offer" || page === "/create-offer" || page === "/offer-history" || page === "/inventory-offer") {
			changeCollapse(10);
		}

		if (page === "/box-opening" || page === "/box-withdrawal" || page === "/box-close" || page === "/box-correction" || page === "/box-close-report" || page === "/box-report"
			|| page === "/box-history" || page === "/box-add") {
			changeCollapse(11);
		}

		if (page === "/resguard-add" || page === "/resguard-withdrawal" || page === "/resguard-report" || page === "/resguard-history" || page === "/withdrawal-report"
			//  || page === "/box-withdrawal" || page === "/box-close" || page === "/box-correction" || page === "/box-close-report" || page === "/box-report" 
		) {
			changeCollapse(12);
		}
		//registrar ventas
		if (page === "/register-sale" ||  page === "/offline-sales" ||  page === "/register-wholesale" || page === "/credit-payment" || page === "/pending-payments") {
			changeCollapse(13);
		}

		if (page === "/operators" || page === "/register-operator" || page === "/update-operator") {
			changeCollapse(14);
		}

		// Reportes de Inventario
		if (page === "/inventory-report" || page === "/balance-report" || page === "/inventory-history" || page === "/inventory-sell" || page === "/departures" || page === "/inventory-adjustment-history") {
			changeCollapse(15);
		}

		// Reportes de clientes
		if (page === "/client-list" || page === "/wholesale-client-list") {
			changeCollapse(16);
		}

		// Reporte de ventas
		if (page === "/sales") {
			changeCollapse(17);
		}

		// Reportes financieros
		if (page === "/commissions-report" || page === "/operators-performance" || page === "/cashiers-performance" || page === "/payment-methods-report" || page === "/payment-methods-history" || page === "/payment-methods-general-report") {
			changeCollapse(18);
		}

		// Reportes de televentas
		if (page === "/telesales" || page === "/telesales-commissions-report" ) {
			changeCollapse(19);
		}

		// Otros reportes
		if (page === "/cron-history") {
			changeCollapse(20);
		}

		// Activos
		if (page === "/assets" || page === "/register-assets" || page === "/update-assets" || page === "/assets-record" || page === "/assets-dump") {
			changeCollapse(21);
		}

		// Códigos de autorizacion
		if (page === "/create-authorization-code" || page === "/authorization-codes") {
			changeCollapse(22);
		}

		// Suministros
		if (page === "/miscellaneous" || page === "/register-miscellaneous" || page === "/update-miscellaneous" || page === "/miscellaneous-history" || page === "/authorization-codes"
			|| page === "/register-miscellaneous-inventory" || page === "/readjustment-miscellaneous" || page === "/departure-miscellaneous" || page === "/inventory-miscellaneous-history" || page === "/inventory-miscellaneous-report") {
			changeCollapse(23);
		}

	}, [location]);

	//ventas offline pendientes
	const pending = useSelector(state => state.pending);

	const [pendingSales, setPendingSales] = useState('');

	//Colocar cantidad de pendientes en el menú
	const getPendings = () => {
		if (pending.sales && pending.sales.length > 0) {
			setPendingSales(`(${pending.sales.length})`);
		} else {
			setPendingSales('')
		}
	};
	useEffect(() => {
		getPendings();
	}, [pending.sales]);

	return (
		<>
			<div className="" id="sidebar-wrapper">
				<div className="sidebar-heading"><NavLink to="/home"> <span className="helper"></span><img alt="..." className="logo" src={fattoria}></img></NavLink></div>

				<div className="list-group list-group-flush" >
					<div aria-multiselectable={true} id="accordion" role="tablist">
						{(user.role == 1 || user.role == 2) &&  //administrador o supervisor
							<>
							

								{/* Sucursales */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(2)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(2); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={storeIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Sucursales</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(2) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(2)}>
									<div className="margin-menu">
										<NavLink to="/agency" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
									{(user.role == 1) && <> 
										<NavLink to="/register-agency" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir</div>
													</div>
												</div>
											</div>
										</NavLink>
										</> 
									}
									</div>
									<div className="margin-menu">
										<NavLink to="/agency-close-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bullhornIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de cierre</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
								{/* Ventas al mayor aquii */}
								{user.role == 1 && <>
							<div className="margin-menu">
									<a aria-expanded={collapses.includes(13)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(13); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={cashRegister} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Registrar Ventas{pendingSales !== '' ? pendingSales : ''}</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(13) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(13)}>
									
									<div className="margin-menu">
										<NavLink to="/credit-payment" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Pagar crédito pendiente</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-wholesale" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cartPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar venta al Mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-sale" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cartPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar venta al Detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/offline-sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookDead} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ventas Offline al Detal {pendingSales !== '' ? pendingSales : ''}</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
												</>}

								{/* Caja de tienda */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(11)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(11); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja de tienda</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(11) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(11)}>
									{/* solo admin caja de tienda */}
									{user.role == 1 && <>
										<div className="margin-menu">
											<NavLink to="/box-opening" activeClassName="item-active" className="menu-child">
												<div className="child-item">
													<div className="d-flex">
														<div className="icon-menu">
															<Icon icon={folderPlus} className="icon" />
														</div>
														<div className="text-menu">
															<div className="text">Apertura</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
										<div className="margin-menu">
											<NavLink to="/box-withdrawal" activeClassName="item-active" className="menu-child">
												<div className="child-item">
													<div className="d-flex">
														<div className="icon-menu">
															<Icon icon={folderMinus} className="icon" />
														</div>
														<div className="text-menu">
															<div className="text">Retiro</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
									</>
									}
									{user.role == 1 && <>
										<div className="margin-menu">
											<NavLink to="/box-add" activeClassName="item-active" className="menu-child">
												<div className="child-item">
													<div className="d-flex">
														<div className="icon-menu">
															<Icon icon={folderMinus} className="icon" />
														</div>
														<div className="text-menu">
															<div className="text">Ingreso</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
									</>
									}
									<div className="margin-menu">
										<NavLink to="/box-close" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cierre</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									{(user.role == 1) && <> 
									<div className="margin-menu">
										<NavLink to="/box-correction" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={pencilAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Corrección</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									</>
									}
									<div className="margin-menu">
										<NavLink to="/box-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-close-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de cierres de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Caja fuerte */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(12)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(12); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja Fuerte</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(12) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(12)}>
									<div className="margin-menu">
										<NavLink to="/resguard-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Dinero actual en caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/resguard-withdrawal" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiro de caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/withdrawal-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiros de la caja fuerte por confirmar</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/resguard-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
								{/* Puntos de venta: solo admin*/}
								{user.role == 1 && <>
									<div className="margin-menu">
										<a aria-expanded={collapses.includes(7)} className="menu-title" data-parent="#accordion" href="#"
											id="collapseOne"
											onClick={e => { e.preventDefault(); changeCollapse(7); }}
										>
											<div className="parent-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={creditCard} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Puntos de venta</div>
													</div>
													<div className="angle-menu">
														<Icon icon={collapses.includes(7) ? angleUp : angleDown} width="18" height="18" />
													</div>
												</div>
											</div>
										</a>
									</div>
									<Collapse isOpen={collapses.includes(7)}>
										<div className="margin-menu">
											<NavLink to="/terminal" activeClassName="item-active" className="menu-child">
												<div className="child-item">
													<div className="d-flex">
														<div className="icon-menu">
															<Icon icon={listAlt} className="icon" />
														</div>
														<div className="text-menu">
															<div className="text">Lista</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
										<div className="margin-menu">
											<NavLink to="/register-terminal" activeClassName="item-active" className="menu-child">
												<div className="child-item">
													<div className="d-flex">
														<div className="icon-menu">
															<Icon icon={plusCircle} className="icon" />
														</div>
														<div className="text-menu">
															<div className="text">Añadir</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
										<div className="margin-menu">
											<NavLink to="/terminal-reports" activeClassName="item-active" className="menu-child">
												<div className="child-item">
													<div className="d-flex">
														<div className="icon-menu">
															<Icon icon={plusCircle} className="icon" />
														</div>
														<div className="text-menu">
															<div className="text">Reporte de puntos</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
									</Collapse>
								</>
								}
								{/* Usuario */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(1)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(1); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={userFriends} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Usuarios</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(1) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(1)}>
									<div className="margin-menu">
										<NavLink to="/users" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-user" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									{user.role ==1 && <>
									<div className="margin-menu">
										<NavLink to="/sellers" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Vendedores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-seller" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir Vendedor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									</>
										}
								</Collapse>

								{/* Códigos de autorizacion */}
								{user.role == 1 && <>
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(22)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(22); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={userFriends} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Códigos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(22) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(22)}>
									<div className="margin-menu">
										<NavLink to="/create-authorization-code" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Crear Código</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/authorization-codes" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Códigos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
								</>}

								{/* Activos */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(21)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(21); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={userFriends} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Activos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(21) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(21)}>
									<div className="margin-menu">
										<NavLink to="/assets" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-assets" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/assets-record" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Movimientos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/assets-dump" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={trashIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Papelera de activos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* trabajadores */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(14)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(14); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={userFriends} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Trabajadores</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(14) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(14)}>
									<div className="margin-menu">
										<NavLink to="/operators" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-operator" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir operador</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Tipo de cambio */}
								{user.role == 1 && <>
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(6)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(6); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={donateIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Tipo de cambio</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(6) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(6)}>
									<div className="margin-menu">
										<NavLink to="/coin" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									{/* <div className="margin-menu">
										<NavLink to="/coin" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista manual</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div> */}
									{/* <div className="margin-menu">
										<NavLink to="/register-coin" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div> */}

									{/* HISTORIAL  */}

									<div className="margin-menu">
										<NavLink to="/history-coin" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
								</>}
								{/* Catalogo */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(3)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(3); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={listUl} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Catálogo de productos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(3) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(3)}>
									<div className="margin-menu">
										<NavLink to="/product" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									{(user.role===1)&&<>
										<div className="margin-menu">
										<NavLink to="/register-product" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div></>}
									<div className="margin-menu">
										<NavLink to="/product-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/combos" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista de Combos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								{(user.role===1) &&<>
								<div className="margin-menu">
									 <NavLink to="/create-combo" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Crear combo</div>
													</div>
												</div>
											</div>
										</NavLink>
										
									</div>
									</>}
									<div className="margin-menu">
										<NavLink to="/record-combo" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de combos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Suministros */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(23)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(23); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={listUl} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Suministros</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(23) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(23)}>
									<div className="margin-menu">
										<NavLink to="/miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/miscellaneous-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-miscellaneous-inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ingresar Suministro</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/readjustment-miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inventario Físico</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departure-miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-miscellaneous-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-miscellaneous-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Inventarios */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(4)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(4); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={dollyIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Inventarios</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(4) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(4)}>
									<div className="margin-menu">
										<NavLink to="/register-inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inicial/Entrada</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/readjustment" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={editIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inventario físico</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/order-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Pedidos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/order-helper" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ayuda a pedidos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-entry-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={editIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de entradas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxesIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Repesaje Diario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Historial fiscal */}

								{/* <div className="margin-menu">
									<a aria-expanded={collapses.includes(19)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(19); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={dollyIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Inventario fiscal</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(19) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(19)}>
									<div className="margin-menu">
										<NavLink to="/register-inventory-fiscal" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inicial/Entrada</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-fiscal-entry-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={editIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de entradas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse> */}

								{/* Ofertas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(10)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(10); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={bullhornIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Ofertas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(10) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(10)}>
									<div className="margin-menu">
										<NavLink to="/offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/offer-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bullhornIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								
								{/* Registro de Salidas */}
								{(user && user.username == "adminDev") && <>
									<div className="margin-menu">
										<a aria-expanded={collapses.includes(9)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
											onClick={e => { e.preventDefault(); changeCollapse(9); }}
										>
											<div className="parent-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
													<div className="angle-menu">
														<Icon icon={collapses.includes(9) ? angleUp : angleDown} width="18" height="18" />
													</div>
												</div>
											</div>
										</a>
									</div>
									<Collapse isOpen={collapses.includes(9)}>
										<div className="margin-menu">
											<NavLink to="/departure" activeClassName="item-active" className="menu-child">
												<div className="child-item">
													<div className="d-flex">
														<div className="icon-menu">
															<Icon icon={fileAlt} className="icon" />
														</div>
														<div className="text-menu">
															<div className="text">Registrar</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
									</Collapse>
								</>
								}

								{/* Reportes */}
								{/*								<div className="margin-menu">
									<a aria-expanded={collapses.includes(8)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne" 
										onClick={e => { e.preventDefault(); changeCollapse(8);}}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon"/>
												</div>
												<div className="text-menu">
													<div className="text">Reportes</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(8) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(8)}>
									<div className="margin-menu">
										<NavLink to="/client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Clientes al detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/wholesale-client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Clientes al mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxesIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Repesaje Diario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Inventarios</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/balance-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={balanceScale} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Balances</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									{/*<div className="margin-menu">
										<NavLink to="/inventory-report-plus" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Inventario bruto</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-report-daily" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardList} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Reporte diario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>*/}
								{/*									<div className="margin-menu">
										<NavLink to="/inventory-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Historial de Inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/commissions-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Reporte de comisiones</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/operators-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-sell" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cashRegister} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Ventas por Kg</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Tickets Registrados</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departures" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Historial de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-general-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Reporte general de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-adjustment-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ajustes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/offer-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bullhornIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									{ (user && user.username == "adminDev" || user.role == 1) && <>
										<div className="margin-menu">
											<NavLink to="/cron-history" activeClassName="item-active" className="menu-child">
												<div className="child-item">
													<div className="d-flex">
														<div className="icon-menu">
															<Icon icon={undoAlt} className="icon"/>
														</div>
														<div className="text-menu">
															<div className="text">Historial de Cron</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
									</>
									}
								</Collapse>*/}

								{/* Reportes de inventario */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(15)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(15); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de inventario</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(15) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(15)}>

									<div className="margin-menu">
										<NavLink to="/inventory-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Inventarios</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/balance-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={balanceScale} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Balance de inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-sell" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cashRegister} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ventas por Kg</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departures" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-adjustment-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ajustes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de clientes */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(16)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(16); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de clientes</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(16) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(16)}>
									<div className="margin-menu">
										<NavLink to="/client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/wholesale-client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de ventas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(17)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(17); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de ventas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(17) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(17)}>
									<div className="margin-menu">
										<NavLink to="/sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Tickets Registrados</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/pending-payments" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cuentas pendientes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/sales-combos-chart" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Gráfico de combos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes financieros*/}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(18)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(18); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes financieros</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(18) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(18)}>
									<div className="margin-menu">
										<NavLink to="/commissions-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de comisiones</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									{/*<div className="margin-menu">
										<NavLink to="/operators-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/cashiers-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los cajeros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>*/}
									<div className="margin-menu">
										<NavLink to="/payment-methods-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-chart" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Gráfico de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-general-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte general de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de televentas*/}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(19)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(19); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de televentas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(19) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(19)}>
									<div className="margin-menu">
										<NavLink to="/telesales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Tickets registrados</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/telesales-commissions-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de comisiones</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/telesales-pending-payments" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cuentas pendientes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								
								{/* Otros reportes */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(20)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(20); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Otros reportes</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(20) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(20)}>
									{(user && user.username == "adminDev" || user.role == 1 || user.role == 2) && <>
										<div className="margin-menu">
											<NavLink to="/cron-history" activeClassName="item-active" className="menu-child">
												<div className="child-item">
													<div className="d-flex">
														<div className="icon-menu">
															<Icon icon={undoAlt} className="icon" />
														</div>
														<div className="text-menu">
															<div className="text">Historial de Cron</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
									</>
									}
								</Collapse>
							</>
						}
						{user.role == 3 && //Gerente
							<>

								{/* Inventarios*/}
								
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(4)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(4); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={dollyIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Inventarios</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(4) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(4)}>
									<div className="margin-menu">
										<NavLink to="/register-inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inicial/Entrada</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/readjustment" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={editIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inventario físico</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/order-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Pedidos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxesIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Repesaje Diario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Catalogo */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(3)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(3); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={listUl} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Catálogo de productos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(3) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(3)}>
									<div className="margin-menu">
										<NavLink to="/product" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div> </Collapse>

								{/* Suministros */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(23)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(23); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={listUl} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Suministros</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(23) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(23)}>
									<div className="margin-menu">
										<NavLink to="/miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Listado de suministros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-miscellaneous-inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ingresar Suministro</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/readjustment-miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inventario físico de suministros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departure-miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar de salidas de suministros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Caja de tienda */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(11)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(11); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja de tienda</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(11) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(11)}>
									<div className="margin-menu">
										<NavLink to="/box-withdrawal" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiro de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-add" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ingreso a caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-close" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cierre de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-close-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de cierres de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Caja fuerte */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(12)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(12); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja fuerte</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(12) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(12)}>
									<div className="margin-menu">
										<NavLink to="/resguard-withdrawal" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiro de caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/withdrawal-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiros de la caja fuerte por confirmar</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/resguard-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Dinero actual en caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/resguard-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>


								{/* Activos */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(21)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(21); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={userFriends} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Activos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(21) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(21)}>
									<div className="margin-menu">
										<NavLink to="/assets" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-assets" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/assets-record" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Movimientos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* trabajadores */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(14)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(14); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={userFriends} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Trabajadores</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(14) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(14)}>
									<div className="margin-menu">
										<NavLink to="/operators" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-operator" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir operador</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Ofertas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(10)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(10); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={bullhornIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Ofertas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(10) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(10)}>
									<div className="margin-menu">
										<NavLink to="/offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/offer-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bullhornIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
								{/* registrar ventas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(13)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(13); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={cashRegister} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Registrar Ventas{pendingSales !== '' ? pendingSales : ''}</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(13) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(13)}>
									<div className="margin-menu">
										<NavLink to="/pending-payments" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cuentas pendientes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/credit-payment" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Pagar crédito pendiente</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-wholesale" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cartPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar venta al Mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-sale" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cartPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar venta al Detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/offline-sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookDead} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ventas Offline al Detal {pendingSales !== '' ? pendingSales : ''}</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Registro de Salidas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(9)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(9); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={boxIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Registro de Salidas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(9) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(9)}>
									<div className="margin-menu">
										<NavLink to="/departure" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={fileAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes */}
								{/*								<div className="margin-menu">
									<a aria-expanded={collapses.includes(8)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne" 
										onClick={e => { e.preventDefault(); changeCollapse(8);}}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon"/>
												</div>
												<div className="text-menu">
													<div className="text">Reportes</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(8) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(8)}>
									<div className="margin-menu">
										<NavLink to="/client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Clientes al detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/wholesale-client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Clientes al mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxesIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Repesaje Diario </div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/balance-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={balanceScale} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Balances</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/operators-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								<div className="margin-menu">
										<NavLink to="/inventory-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Inventarios</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									{/*<div className="margin-menu">
										<NavLink to="/inventory-report-plus" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Inventario bruto</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-report-daily" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardList} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Reporte diario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>*/}
								{/*									<div className="margin-menu">
										<NavLink to="/inventory-sell" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cashRegister} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Ventas por Kg</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Tickts Registrados</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departures" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-adjustment-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ajustes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/offer-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bullhornIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>*/}

								{/* Reportes de inventario */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(15)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(15); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de inventario</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(15) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(15)}>

									<div className="margin-menu">
										<NavLink to="/inventory-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Inventarios</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/balance-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={balanceScale} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Balance de inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-sell" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cashRegister} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ventas por Kg</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departures" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-adjustment-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ajustes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de clientes */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(16)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(16); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de clientes</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(16) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(16)}>
									<div className="margin-menu">
										<NavLink to="/client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/wholesale-client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de ventas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(17)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(17); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de ventas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(17) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(17)}>
									<div className="margin-menu">
										<NavLink to="/sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Tickets Registrados</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/pending-payments" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cuentas pendientes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes financieros*/}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(18)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(18); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes financieros</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(18) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(18)}>
									{/*<div className="margin-menu">
										<NavLink to="/operators-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/cashiers-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los cajeros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>*/}
									<div className="margin-menu">
										<NavLink to="/payment-methods-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

							</>
						}
						{user.role == 4 && //Cajero
							<>
								{/* Catalogo */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(3)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(3); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={listUl} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Catálogo de productos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(3) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(3)}>
									<div className="margin-menu">
										<NavLink to="/product" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div> </Collapse>

								{/* Reportes */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(8)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(8); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(8) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(8)}>
									<div className="margin-menu">
										<NavLink to="/sales-user" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Tickets registrados</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxesIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inventario Actual</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Ventas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(5)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(5); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={cashRegister} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Ventas al detal {pendingSales !== '' ? pendingSales : ''}</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(5) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(5)}>

									<div className="margin-menu">
										<NavLink to="/offline-sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookDead} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ventas Offline al Detal {pendingSales !== '' ? pendingSales : ''}</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-sale" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cartPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar venta al Detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
								{/* Ventas al mayor */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(13)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(13); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={cashRegister} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Ventas al mayor</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(13) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(13)}>
									<div className="margin-menu">
										<NavLink to="/credit-payment" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Pagar crédito pendiente</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/pending-payments" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cuentas pendientes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-wholesale" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cartPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar venta al Mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
							</>
						}
						{user.role == 5 && // Departamento de cobranzas
							<>
								{/* Caja de tienda */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(11)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(11); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(11) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(11)}>
									{/* solo admin caja */}

									<div className="margin-menu">
										<NavLink to="/resguard-add" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiro</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-add" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ingreso</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								{	/*<div className="margin-menu">
										<NavLink to="/box-close" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cierre</div>
													</div>
												</div>
											</div>
										</NavLink>
					</div>*/}
									<div className="margin-menu">
										<NavLink to="/box-correction" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={pencilAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Corrección</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-close-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de cierres de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Caja fuerte */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(12)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(12); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja Fuerte</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(12) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(12)}>
									<div className="margin-menu">
										<NavLink to="/resguard-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Dinero actual en caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/resguard-withdrawal" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retirar de caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/withdrawal-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiros de la caja fuerte por confirmar</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/resguard-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Catalogo */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(3)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(3); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={listUl} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Catálogo de productos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(3) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(3)}>
									<div className="margin-menu">
										<NavLink to="/product" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/combos" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista de Combos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Inventarios */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(4)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(4); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={dollyIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Inventarios</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(4) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(4)}>
									<div className="margin-menu">
										<NavLink to="/inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={editIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inventario físico</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/order-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Pedidos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								{/*	<div className="margin-menu">
										<NavLink to="/order-helper" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ayuda a pedidos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>*/}
								</Collapse>

								{/* Ofertas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(10)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(10); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={bullhornIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Ofertas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(10) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(10)}>
									<div className="margin-menu">
										<NavLink to="/offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

							

								{/* Reportes de clientes */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(16)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(16); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de clientes</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(16) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(16)}>
									<div className="margin-menu">
										<NavLink to="/client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/wholesale-client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de ventas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(17)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(17); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de ventas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(17) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(17)}>
									<div className="margin-menu">
										<NavLink to="/sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Tickets Registrados</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/pending-payments" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cuentas pendientes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
								{/* Reportes de inventario */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(15)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(15); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de inventario</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(15) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(15)}>

									<div className="margin-menu">
										<NavLink to="/inventory-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Inventarios</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/balance-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={balanceScale} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Balance de inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-sell" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cashRegister} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ventas por Kg</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departures" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-adjustment-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ajustes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes financieros*/}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(18)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(18); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes financieros</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(18) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(18)}>
									{/* <div className="margin-menu">
										<NavLink to="/commissions-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de comisiones</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div> */}
									{/*<div className="margin-menu">
										<NavLink to="/operators-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/cashiers-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los cajeros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>*/}
									<div className="margin-menu">
										<NavLink to="/payment-methods-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									{/* <div className="margin-menu">
										<NavLink to="/payment-methods-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div> */}
									{/* <div className="margin-menu">
										<NavLink to="/payment-methods-general-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte general de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div> */}
								</Collapse>
							</>
						}
						{user.role == 6  && // Auditor de inventarios
							<> 
								{/* Caja de tienda */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(11)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(11); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja de tienda</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(11) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(11)}>

									<div className="margin-menu">
										<NavLink to="/box-close" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cierre</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>

									<div className="margin-menu">
										<NavLink to="/box-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-close-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de cierres de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Caja fuerte */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(12)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(12); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja Fuerte</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(12) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(12)}>
									<div className="margin-menu">
										<NavLink to="/resguard-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Dinero actual en caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									
									<div className="margin-menu">
										<NavLink to="/withdrawal-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiros de la caja fuerte por confirmar</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/resguard-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Catalogo */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(3)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(3); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={listUl} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Catálogo de productos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(3) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(3)}>
									<div className="margin-menu">
										<NavLink to="/product" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/combos" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista de Combos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Inventarios*/}
								
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(4)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(4); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={dollyIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Inventarios</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(4) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(4)}>
									<div className="margin-menu">
										<NavLink to="/register-inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inicial/Entrada</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/readjustment" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={editIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inventario físico</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/order-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Pedidos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								{/*	<div className="margin-menu">
										<NavLink to="/order-helper" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ayuda a pedidos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>*/}
									<div className="margin-menu">
										<NavLink to="/inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxesIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Repesaje Diario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								
								{/* Activos */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(21)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(21); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={userFriends} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Activos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(21) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(21)}>
									<div className="margin-menu">
										<NavLink to="/assets" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								
									<div className="margin-menu">
										<NavLink to="/assets-record" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Movimientos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/assets-dump" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={trashIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Papelera de activos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>



								{/* Ofertas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(10)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(10); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={bullhornIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Ofertas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(10) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(10)}>
									<div className="margin-menu">
										<NavLink to="/offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de inventario */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(15)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(15); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de inventario</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(15) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(15)}>
								<div className="margin-menu">
										<NavLink to="/balance-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={balanceScale} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Balance de inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Inventarios</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-sell" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cashRegister} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ventas por Kg</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departures" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-adjustment-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ajustes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de clientes */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(16)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(16); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de clientes</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(16) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(16)}>
									<div className="margin-menu">
										<NavLink to="/client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/wholesale-client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de ventas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(17)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(17); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de ventas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(17) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(17)}>
									<div className="margin-menu">
										<NavLink to="/sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Tickets Registrados</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/pending-payments" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cuentas pendientes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes financieros*/}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(18)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(18); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes financieros</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(18) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(18)}>
									{/* <div className="margin-menu">
										<NavLink to="/commissions-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de comisiones</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div> */}
								{/*	<div className="margin-menu">
										<NavLink to="/operators-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/cashiers-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los cajeros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>*/}
									<div className="margin-menu">
										<NavLink to="/payment-methods-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									{/* <div className="margin-menu">
										<NavLink to="/payment-methods-general-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte general de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div> */}
								</Collapse>
							</>
						}
						{user.role == 7 && // Televentas
							<>
							{/* Tipo de cambio */}
							 <>
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(6)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(6); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={donateIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Tipo de cambio</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(6) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(6)}>
									<div className="margin-menu">
										<NavLink to="/coin" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-coin" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
								</>
								{/* Sucursales */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(2)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(2); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={storeIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Sucursales</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(2) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(2)}>
									<div className="margin-menu">
										<NavLink to="/agency" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
									{(user.role == 1) && <> 
										<NavLink to="/register-agency" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir</div>
													</div>
												</div>
											</div>
										</NavLink>
										</> 
									}
									</div>
								</Collapse>
								{/* Inventarios*/}
								
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(4)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(4); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={dollyIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Inventarios</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(4) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(4)}>
									<div className="margin-menu">
										<NavLink to="/register-inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inicial/Entrada</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/readjustment" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={editIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inventario físico</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/order-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Pedidos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxesIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Repesaje Diario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Puntos de venta: solo admin*/}
								{user.role == 7 && <>
									<div className="margin-menu">
										<a aria-expanded={collapses.includes(7)} className="menu-title" data-parent="#accordion" href="#"
											id="collapseOne"
											onClick={e => { e.preventDefault(); changeCollapse(7); }}
										>
											<div className="parent-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={creditCard} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Puntos de venta</div>
													</div>
													<div className="angle-menu">
														<Icon icon={collapses.includes(7) ? angleUp : angleDown} width="18" height="18" />
													</div>
												</div>
											</div>
										</a>
									</div>
									<Collapse isOpen={collapses.includes(7)}>
										<div className="margin-menu">
											<NavLink to="/terminal" activeClassName="item-active" className="menu-child">
												<div className="child-item">
													<div className="d-flex">
														<div className="icon-menu">
															<Icon icon={listAlt} className="icon" />
														</div>
														<div className="text-menu">
															<div className="text">Lista</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
										<div className="margin-menu">
											<NavLink to="/register-terminal" activeClassName="item-active" className="menu-child">
												<div className="child-item">
													<div className="d-flex">
														<div className="icon-menu">
															<Icon icon={plusCircle} className="icon" />
														</div>
														<div className="text-menu">
															<div className="text">Añadir</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
									</Collapse>
								</>
								}

								{/* Catalogo */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(3)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(3); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={listUl} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Catálogo de productos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(3) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(3)}>
									<div className="margin-menu">
										<NavLink to="/product" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div> </Collapse>

								{/* Suministros */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(23)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(23); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={listUl} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Suministros</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(23) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(23)}>
									<div className="margin-menu">
										<NavLink to="/miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Listado de suministros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-miscellaneous-inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ingresar Suministro</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/readjustment-miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inventario físico de suministros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departure-miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar de salidas de suministros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Caja de tienda */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(11)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(11); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja de tienda</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(11) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(11)}>
									<div className="margin-menu">
										<NavLink to="/box-withdrawal" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiro de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-add" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ingreso a caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-close" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cierre de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-close-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de cierres de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Caja fuerte */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(12)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(12); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja fuerte</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(12) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(12)}>
									<div className="margin-menu">
										<NavLink to="/resguard-withdrawal" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiro de caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/withdrawal-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiros de la caja fuerte por confirmar</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/resguard-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Dinero actual en caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/resguard-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>


								{/* Activos */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(21)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(21); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={userFriends} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Activos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(21) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(21)}>
									<div className="margin-menu">
										<NavLink to="/assets" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-assets" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/assets-record" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Movimientos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* trabajadores */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(14)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(14); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={userFriends} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Trabajadores</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(14) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(14)}>
									<div className="margin-menu">
										<NavLink to="/operators" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-operator" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir operador</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Ofertas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(10)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(10); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={bullhornIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Ofertas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(10) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(10)}>
									<div className="margin-menu">
										<NavLink to="/offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/offer-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bullhornIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
								{/* registrar ventas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(13)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(13); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={cashRegister} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Registrar Ventas{pendingSales !== '' ? pendingSales : ''}</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(13) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(13)}>
									<div className="margin-menu">
										<NavLink to="/pending-payments" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cuentas pendientes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/credit-payment" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Pagar crédito pendiente</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-wholesale" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cartPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar venta al Mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-sale" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cartPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar venta al Detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/offline-sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookDead} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ventas Offline al Detal {pendingSales !== '' ? pendingSales : ''}</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Registro de Salidas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(9)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(9); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={boxIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Registro de Salidas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(9) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(9)}>
									<div className="margin-menu">
										<NavLink to="/departure" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={fileAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registrar</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes */}
								{/*								<div className="margin-menu">
									<a aria-expanded={collapses.includes(8)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne" 
										onClick={e => { e.preventDefault(); changeCollapse(8);}}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon"/>
												</div>
												<div className="text-menu">
													<div className="text">Reportes</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(8) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(8)}>
									<div className="margin-menu">
										<NavLink to="/client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Clientes al detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/wholesale-client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Clientes al mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxesIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Repesaje Diario </div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/balance-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={balanceScale} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Balances</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/operators-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								<div className="margin-menu">
										<NavLink to="/inventory-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Inventarios</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									{/*<div className="margin-menu">
										<NavLink to="/inventory-report-plus" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Inventario bruto</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-report-daily" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardList} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Reporte diario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>*/}
								{/*									<div className="margin-menu">
										<NavLink to="/inventory-sell" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cashRegister} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Ventas por Kg</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Tickts Registrados</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departures" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-adjustment-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ajustes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/offer-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bullhornIcon} className="icon"/>
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>*/}

								{/* Reportes de inventario */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(15)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(15); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de inventario</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(15) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(15)}>

									<div className="margin-menu">
										<NavLink to="/inventory-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Inventarios</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/balance-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={balanceScale} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Balance de inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-sell" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cashRegister} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ventas por Kg</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departures" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-adjustment-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ajustes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de clientes */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(16)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(16); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de clientes</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(16) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(16)}>
									<div className="margin-menu">
										<NavLink to="/client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/wholesale-client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de ventas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(17)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(17); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de ventas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(17) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(17)}>
									<div className="margin-menu">
										<NavLink to="/sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Tickets Registrados</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/pending-payments" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cuentas pendientes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes financieros*/}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(18)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(18); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes financieros</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(18) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(18)}>
									{/*<div className="margin-menu">
										<NavLink to="/operators-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/cashiers-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los cajeros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>*/}
									<div className="margin-menu">
										<NavLink to="/payment-methods-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								
							</>
						}
						{(user.role == 8) &&  //Usuario de suministros
							<>
								{/* Suministros */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(23)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(23); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={listUl} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Suministros</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(23) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(23)}>
									<div className="margin-menu">
										<NavLink to="/miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={plusCircle} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Añadir</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/miscellaneous-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/register-miscellaneous-inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ingresar Suministro</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/readjustment-miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inventario Físico</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departure-miscellaneous" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-miscellaneous-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-miscellaneous-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
							</>
						}
						{user.role == 9  && // Auditor de financiero
							<> 
								{/* Caja de tienda */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(11)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(11); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja de tienda</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(11) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(11)}>

									<div className="margin-menu">
										<NavLink to="/box-close" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cierre</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>

									<div className="margin-menu">
										<NavLink to="/box-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-close-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de cierres de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/box-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tableIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de caja de tienda</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Caja fuerte */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(12)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(12); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={briefcaseIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Caja Fuerte</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(12) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(12)}>
									<div className="margin-menu">
										<NavLink to="/resguard-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderPlus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Dinero actual en caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									
									<div className="margin-menu">
										<NavLink to="/withdrawal-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={folderMinus} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Retiros de la caja fuerte por confirmar</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/resguard-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={tasksIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de caja fuerte</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Catalogo */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(3)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(3); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={listUl} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Catálogo de productos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(3) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(3)}>
									<div className="margin-menu">
										<NavLink to="/product" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/combos" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista de Combos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Inventarios*/}
								
								{/* <div className="margin-menu">
									<a aria-expanded={collapses.includes(4)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(4); }}
									>
										<div className="parent-item first-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={dollyIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Inventarios</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(4) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(4)}>
									<div className="margin-menu">
										<NavLink to="/register-inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={doorOpen} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inicial/Entrada</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/readjustment" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={editIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Inventario físico</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/order-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Pedidos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/order-helper" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ayuda a pedidos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxesIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Repesaje Diario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse> */}

								
								{/* Activos */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(21)} className="menu-title" data-parent="#accordion" href="#"
										id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(21); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={userFriends} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Activos</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(21) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(21)}>
									<div className="margin-menu">
										<NavLink to="/assets" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Lista</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								
									<div className="margin-menu">
										<NavLink to="/assets-record" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={listAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Movimientos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/assets-dump" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={trashIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Papelera de activos</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>



								{/* Ofertas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(10)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(10); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={bullhornIcon} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Ofertas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(10) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(10)}>
									<div className="margin-menu">
										<NavLink to="/offer" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ofertas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de inventario */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(15)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(15); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de inventario</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(15) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(15)}>
								<div className="margin-menu">
										<NavLink to="/balance-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={balanceScale} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Balance de inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={clipboardIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de Inventarios</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Inventario</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-sell" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={cashRegister} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Ventas por Kg</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/departures" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={boxIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Registro de Salidas</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/inventory-adjustment-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={undoAlt} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de Ajustes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de clientes */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(16)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(16); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de clientes</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(16) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(16)}>
									<div className="margin-menu">
										<NavLink to="/client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al detal</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/wholesale-client-list" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={maleIcon} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Clientes al mayor</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes de ventas */}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(17)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(17); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes de ventas</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(17) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(17)}>
									<div className="margin-menu">
										<NavLink to="/sales" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Tickets Registrados</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/pending-payments" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={bookMedical} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Cuentas pendientes</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>

								{/* Reportes financieros*/}
								<div className="margin-menu">
									<a aria-expanded={collapses.includes(18)} className="menu-title" data-parent="#accordion" href="#" id="collapseOne"
										onClick={e => { e.preventDefault(); changeCollapse(18); }}
									>
										<div className="parent-item">
											<div className="d-flex">
												<div className="icon-menu">
													<Icon icon={clipboardCheck} className="icon" />
												</div>
												<div className="text-menu">
													<div className="text">Reportes financieros</div>
												</div>
												<div className="angle-menu">
													<Icon icon={collapses.includes(18) ? angleUp : angleDown} width="18" height="18" />
												</div>
											</div>
										</div>
									</a>
								</div>
								<Collapse isOpen={collapses.includes(18)}>
									<div className="margin-menu">
										<NavLink to="/commissions-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte de comisiones</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								{/*	<div className="margin-menu">
										<NavLink to="/operators-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los operadores</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/cashiers-performance" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={chartLine} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Rendimiento de los cajeros</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>*/}
									<div className="margin-menu">
										<NavLink to="/payment-methods-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-history" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Historial de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-chart" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Gráfico de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
									<div className="margin-menu">
										<NavLink to="/payment-methods-general-report" activeClassName="item-active" className="menu-child">
											<div className="child-item">
												<div className="d-flex">
													<div className="icon-menu">
														<Icon icon={moneyBillWave} className="icon" />
													</div>
													<div className="text-menu">
														<div className="text">Reporte general de formas de pago</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								</Collapse>
							</>
						}
					</div>
				</div>
			</div>
		</>
	);
}

export default SideBar;
